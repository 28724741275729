import React from 'react';
// import { useTheme, useMediaQuery, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SectionAlternate, Section } from 'components/organisms';
import { 
QuoteForm, 
// Hero, 
Providers, 
Subscription, 
YouTubeVideoModal 
} from 'components/windrate';
import pagedata from "content/pages/getaquote.yaml";
import subscription from "content/global/subscription.yaml";
// import { GpsFixed } from '@material-ui/icons';

//const hero = pagedata.hero;
const providers = pagedata.providers; 
const quoteForm = pagedata.quoteForm;

const useStyles = makeStyles(theme => ({
  sectionAlternate: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 400px, ${theme.palette.primary.dark} 0%)`,
  },
  sectionAlternate2: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 40%, ${theme.palette.primary.dark} 0%)`,
  },  
}));

const GetAQuoteView = ({ themeMode }) => {
  const classes = useStyles();
  // const theme = useTheme();
  /*
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const scrollTo = id => {
    setTimeout(() => {
      const element = document.querySelector(`#${id}`);
      if (!element) {
        return;
      }

      window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
    });
  };  
  */
  return (
    <div>
      <YouTubeVideoModal />
      <div style={{height:"90px"}}/>
      <SectionAlternate className={classes.sectionAlternate}>
          <QuoteForm data={quoteForm} show_extended_fields={true}/>
      </SectionAlternate>
      <Section>
          <Providers data={providers} />
      </Section>
      <SectionAlternate innerNarrowed className={classes.sectionAlternate2}>
          <Subscription data={subscription} />
      </SectionAlternate>
    </div>
  );
}

export default GetAQuoteView;
